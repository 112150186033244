import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/Factors/Factors.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Space, Select, Input, Modal,InputNumber, DatePicker as DatePickers } from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  CUSTOMERS,
  DRUGS,
  DRUGTYPES,
  LASTFACTOR,
  STACKS,
} from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import { ADDCUSTOMERS, ADDDRUG, ADDFACTOR } from "../../GraphQL/Mutation";
import { Alert } from "../StaticComponents/Alert";
import { PROVINCES } from "../../Constant/const";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
const { Option } = Select;
export function BuyFactor() {
  const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY-MM-DD" })
  const today = date.format()
  const client = useApolloClient();
  var newTotal = 0;
  const [factorTotal, setfactorTotal] = useState();
  const [factorDate, setfactorDate] = useState("");
  const [selectedDrug, setSelectedDrug] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [tableRows, setTableRows] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [drugs, setDrugs] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [addDrugs, setAddDrug] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [drugTypes, setDrugTypes] = useState([]);
  const [stacks, setStacks] = useState([]);
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [factoreNumber, setFactorNumber] = useState(1);
  const [factorDescription, setFactorDescription] = useState("");
  const [showDark, setShowDark] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedStack, setSelectedStack] = useState(""); 
  const [selectedDrugType, setSelectedDrugType] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  if (location.pathname == "/buyFactor") {
    location.pathname = "فاکتور خرید";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadCustomers();
    loadDrugs();
    loadStacks();
    handleLastFactor();
    loadDrugTypes();
  }, [factoreNumber, referesh, selectedDrug, tableRows]);

  function goToPrintFactor() {
    navigate("/printFactor", { state: { factorType: "Buy" } });
  }
  async function loadCustomers() {
    try {
      const res = await client.query({
        query: CUSTOMERS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      setCustomers([...res.data.getCustomers]);
    } catch (error) {
      console.log(error);
    }
  }
  async function loadDrugs() {
    try {
      const res = await client.query({
        query: DRUGS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      setDrugs([...res.data.getDrugs]);
    } catch (error) {
      console.log(error);
    }
  }
  const handleLastFactor = async () => {
    try {
      const res = await client.query({
        query: LASTFACTOR,
        variables: {
          factorType: "Buy",
        },
        fetchPolicy: "no-cache",
      });
      setFactorNumber(res.data.getLastFactor.buyFactorNumber + 1);
    } catch (error) {
      console.log(error);
    }
  };
  function deleteRow(e) {
    const rowId = Number(e.target.id);
    const filteredTableRows = tableRows.filter((item, index) => index != rowId);
    setTableRows(filteredTableRows);
  }
  function multiplyCount(e) {
    const rowId = Number(e.target.id);
    const count = Number(e.target.value);
    const updatedTableRows = tableRows.map((item, index) => {
      if (rowId == index) {
        item.amount = count;
        return item;
      } else {
        return item;
      }
    });
    setTableRows(updatedTableRows);
  }
  function multiplyCost(e) {
    const rowId = Number(e.target.id);
    const price = Number(e.target.value);
    const updatedTableRows = tableRows.map((item, index) => {
      if (rowId == index) {
        item.price = price;
        return item;
      } else {
        return item;
      }
    });
    setTableRows(updatedTableRows);
  }
  function saveFactor() {
    setShowSavePopup(!showSavePopup);
    setShowDark(!showDark);
    setfactorDate(today);
    setfactorTotal(newTotal);
  }
  const handleSaveFactor = async (e) => {
    e.preventDefault();
    setShowDark(!showDark);
    let factorItems = {};
    const updatedTableRows = tableRows.map((item) => {
      factorItems = {
        drug: item._id,
        quantity: item.amount,
        price: item.price,
        total: item.price * item.amount,
        description: "this is item description",
      };
      return factorItems;
    });
    try {
      setAlert({
        action: "success",
        alertStatus: "create",
      });
      await client.mutate({
        mutation: ADDFACTOR,
        variables: {
          factorType: "Buy",
          paymentType: selectedPayment,
          date: document.getElementById("inputDate").value,
          amount: Number(e.target.amount.value),
          description: factorDescription,
          customer: selectedCustomerId,
          discount: Number(discount),
          items: updatedTableRows,
        },
        fetchPolicy: "no-cache",
      });
      setShowSavePopup(!showSavePopup);
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
      setTableRows([]);
      document.getElementById("factorDescription").value = "";
      setDiscount(0);
      setReferesh(!referesh);
      handleLastFactor();
    } catch (error) {
      console.log(error);
      setAlert({
        type: "",
        action: "",
        alertStatus: "",
      });
    }
  };
  async function loadDrugTypes() {
    try {
      const res = await client.query({
        query: DRUGTYPES,
        variables: {},
        fetchPolicy: "no-cache",
      });
      setDrugTypes([...res.data.getDrugTypes]);
    } catch (error) {
      console.log(error);
    }
  }
  async function loadStacks() {
    try {
      const res = await client.query({
        query: STACKS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      setStacks([...res.data.getStacks]);
    } catch (error) {
      console.log(error);
    }
  }
  async function addNewDrug(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDDRUG,
        variables: {
          name: e.target.drugName.value,
          drugType: selectedDrugType,
          stack: selectedStack,
          company: e.target.drugCompany.value,
          country: e.target.drugCountry.value,
          expDate: e.target.drugExpDate.value,
          amount: 0,
          price: 0,
        },
      });
      if (res) {
        setAddDrug(false);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
        // setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      setAlert({
        action: "",
        alertStatus: "",
      });
      console.log(error);
    }
  }
  async function addNewCustomer(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDCUSTOMERS,
        variables: {
          fullName: e.target.customerName.value,
          company:
            e.target.customerCampany.value != ""
              ? e.target.customerCampany.value
              : undefined,
          category:
            e.target.customerCatagory.value != ""
              ? e.target.customerCatagory.value
              : undefined,
          address:
            e.target.customerAddress.value != ""
              ? e.target.customerAddress.value
              : undefined,
          phoneNumber:
            e.target.customerPhone.value != ""
              ? e.target.customerPhone.value
              : undefined,
          city: selectedProvince,
          balance: 0,
        },
      });
      if (res) {
        setNewCustomer(!newCustomer);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="buyFactor-container">
          <div className="file-header">
            {
              userRole === "Admin" &&
              <Space>
                <Button
                  type="primary"
                  size="large"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setAddDrug(!addDrugs);
                    setShowDark(!showDark);
                  }}
                  className="btn"
                >
                  ثبت دارو
                </Button>
                <Button
                  type="primary"
                  size="large"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setNewCustomer(!newCustomer);
                    setShowSelect(!showSelect);
                  }}
                  className="btn"

                >
                  ثبت مشتری
                </Button>
                <Button
                  type="primary"
                  size="large"
                  icon={<PlusOutlined />}
                  onClick={saveFactor}
                  className="btn"
                >
                  ثبت فاکتور
                </Button>
                <Button
                  type="primary"
                  size="large"
                  icon={<PrinterOutlined />}
                  onClick={goToPrintFactor}
                  className="btn"
                >
                  چاپ فاکتور
                </Button>
              </Space>
            }
             <Space className="inputDate-container">
              <DatePicker
                calendar={persian}
                locale={persian_fa}
                months={MONTHS}
                calendarPosition="top-right"
                format="YYYY-MM-DD"
                inputClass="inputDateFactor"
                value={today}
                id="inputDate"
                name="inputDate"
              />
            </Space>
            <Input
              id="checkNumber"
              name="checkNumber"
              size="large"
              value={factoreNumber}
              type="disable"
              style={{ width: "200px", direction: "rtl", textAlign: "center",fontFamily:"B-NAZANIN" }}
            />
          </div>
          <div className="file-header">
          <Select
              showSearch
              optionFilterProp="children"
              placeholder="جستجوی دارو"
              style={{display:tableRows.length > 14? "none":"block",direction:"rtl",width:"250px"}}
              name="drugs"
              onSelect={(value) => {
                const filteredDrugs = drugs.filter((item) => item._id === value)
                setTableRows([...tableRows, filteredDrugs[0]])
              }}
              size="large"
              className="factor-search"
            >
              {drugs.map((drug) => (
                <Option
                  key={drug._id}
                  value={drug._id}
                  style={{ direction: "rtl" }}
                >
                    {
                      drug?.drugType?.title + "-" +
                      drug.name + "-" +
                      drug.company + "-" +
                      drug.expDate 
                    }
                    
                </Option>
              ))}
            </Select>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="جستجوی مشتری"
              style={{direction:"rtl",width:"250px"}}
              name="customer"
              onChange={(value) => {
                const filteredCustomers = customers.filter((item) => item._id === value)
                setSelectedCustomer(filteredCustomers[0])
                setSelectedCustomerId(filteredCustomers[0]._id)
              }}
              size="large"
            >
              {customers.map((customer) => (
                <Option
                  key={customer._id}
                  value={customer._id}
                  style={{ direction: "rtl" }}
                >
                  {customer.fullName +
                    " - " +
                    customer.company +
                    " - " +
                    customer.city}
                </Option>
              ))}
            </Select>
            <Input
              id="checkNumber"
              name="checkNumber"
              size="large"
              placeholder="بلانس مشتری"
              value={
                (Math.abs(selectedCustomer?.balance)
                ? Math.abs(selectedCustomer?.balance)
                : selectedCustomer?.balance===0 ? 0 : "") +
                  " "
                  +
                  (selectedCustomer?.balance > 0
                    ? "  بستانکار  "
                    : selectedCustomer?.balance < 0
                      ? "  بدهکار  "
                      : selectedCustomer?.balance === 0
                        ? "  تسویه  "
                        : "")
              }
            type="disable"
            style={{ width: "200px", direction: "rtl", textAlign: "center",fontFamily:"B-NAZANIN" }}
            />
          </div>
          {addDrugs && (
              <Modal
                title="ثبت دارو"
                open={addDrugs}
                onCancel={() => {
                  setAddDrug(!addDrugs);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewDrug}>
                  <Space direction="vertical">
                    <Input
                      placeholder="نام دارو"
                      id="drugName"
                      name="drugName"
                      size="large"
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="نوعیت دارو"
                      style={{ width: "100%", direction: "rtl" }}
                      name="stack"
                      onChange={(value) => {
                        setSelectedDrugType(value);
                      }}
                      size="large"
                    >
                      {drugTypes.map((drugType) => (
                        <Option
                          key={drugType._id}
                          value={drugType._id}
                          style={{ direction: "rtl" }}
                        >
                          {drugType.title}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="گدام داروها"
                      style={{ width: "100%", direction: "rtl" }}
                      name="stack"
                      onChange={(value) => {
                        setSelectedStack(value);
                      }}
                      size="large"
                    >
                      {stacks.map((stack) => (
                        <Option
                          key={stack._id}
                          value={stack._id}
                          style={{ direction: "rtl" }}
                        >
                          {stack.name}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      placeholder="کمپنی دارو"
                      id="drugCompany"
                      name="drugCompany"
                      size="large"
                    />
                    <Input
                      placeholder="کشور دارو"
                      id="drugCountry"
                      name="drugCountry"
                      size="large"
                    />
                    <DatePickers
                      placeholder="تاریخ دارو"
                      placement="topRight"
                      id="drugExpDate"
                      name="drugExpDate"
                      size="large"
                      style={{width:"100%"}}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت دارو
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
          {newCustomer && (
              <Modal
                title="ثبت مشتری"
                open={newCustomer}
                onCancel={() => {
                  setNewCustomer(!newCustomer);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewCustomer}>
                  <Space direction="vertical">
                    <Input
                      placeholder="نام مشتری"
                      id="customerName"
                      name="customerName"
                      size="large"
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="ولایت مشتری"
                      style={{ width: "100%", direction: "rtl" }}
                      name="customerProvince"
                      onChange={(value) => {
                        setSelectedProvince(value);
                      }}
                      size="large"
                    >
                      {PROVINCES.map((province) => (
                        <Option
                          key={province}
                          value={province}
                          style={{ direction: "rtl" }}
                        >
                          {province}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      placeholder="کتگوری مشتری"
                      id="customerCatagory"
                      name="customerCatagory"
                      size="large"
                    />
                    <Input
                      placeholder="کمپنی مشتری"
                      id="customerCampany"
                      name="customerCampany"
                      size="large"
                    />
                    <Input
                      placeholder="آدرس مشتری"
                      id="customerAddress"
                      name="customerAddress"
                      size="large"
                    />
                    <Input
                      placeholder="موبایل مشتری"
                      id="customerPhone"
                      name="customerPhone"
                      size="large"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت مشتری
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
          {showSavePopup && (
                <Modal
                title="ثبت مشتری"
                open={showSavePopup}
                onCancel={() => {
                  setShowSavePopup(!showSavePopup);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleSaveFactor}>
                  <Space direction="vertical">
                    <Input
                      id="customerName"
                      name="customerName"
                      size="large"
                      value={selectedCustomer.fullName}
                      type="disable"
                    />
                    <Input
                      id="inputDate"
                      name="inputDate"
                      size="large"
                      value={document.getElementById("inputDate").value}
                      type="disable"
                    />
                    <Input
                      id="factoreNumber"
                      name="factoreNumber"
                      size="large"
                      value={factoreNumber}
                      type="disable"
                    />
                    <Input
                      id="amount"
                      name="amount"
                      size="large"
                      value={factorTotal.toFixed(2)}
                      type="disable"
                    />
                    <InputNumber
                      placeholder="تخفیف"
                      id="discount"
                      name="discount"
                      size="large"
                      min={0}
                      style={{
                        width: "100%",
                      }}
                      defaultValue={0}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="نوعیت پرداخت را انتخاب کنید"
                      style={{ width: "100%", direction: "rtl" }}
                      name="paymentType"
                      onChange={(value) => {
                        setSelectedPayment(value);
                      }}
                      size="large"
                    >
                        <Option
                          key="Cash"
                          value="Cash"
                          style={{ direction: "rtl" }}
                        >
                          نقده
                        </Option>
                        <Option
                          key="No_Cash"
                          value="No_Cash"
                          style={{ direction: "rtl" }}
                        >
                          نسیه
                        </Option>
                    </Select>       
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت فاکتور
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
              )}
          <div className="factor-container">
            <form action="#">
              <div className="factor-heading">
                <span className="factor-code">ردیف</span>
                <span className="factor-type">نوعیت</span>
                <span className="factor-name">نام</span>
                <span className="factor-company">کمپنی</span>
                <span className="factor-contery">کشور</span>
                <span className="factor-count">تعداد</span>
                <span className="factor-cost">فی</span>
                <span className="factor-total">جمع</span>
                <span className="factor-explaination">شرح دارو</span>
              </div>
              <div className="factor-content">
                {tableRows.map((row, index) => {
                  newTotal += Number(row?.price) * Number(row?.amount);
                  return (
                    <div className="factor-content__row" key={index + 1}>
                      <input
                        type="text"
                        className="firstRow factor-code"
                        value={index + 1}
                        readOnly
                      />
                      <input
                        type="text"
                        value={row?.drugType?.title}
                        className="firstRow factor-type"
                        readOnly
                      />
                      <input
                        type="text"
                        value={row.name}
                        className="firstRow factor-name"
                        readOnly
                      />
                      <input
                        type="text"
                        value={row.company}
                        className="firstRow factor-company"
                        readOnly
                      />
                      <input
                        type="text"
                        value={row.country}
                        className="firstRow factor-contery"
                        readOnly
                      />
                      <input
                        type="number"
                        defaultValue={row.amount}
                        className="firstRow factor-count"
                        min={1}
                        id={index}
                        name={row._id}
                        onChange={multiplyCount}
                      />
                      <input
                        type="number"
                        defaultValue={row.price}
                        className="firstRow factor-cost"
                        min={1}
                        id={index}
                        name={row._id}
                        onChange={multiplyCost}
                      />
                      <input
                        type="number"
                        value={(row.amount * row.price).toFixed(2)}
                        className="firstRow factor-total"
                        readOnly
                      />
                      <input
                        type="text"
                        className="firstRow factor-explaination"
                      />
                      <img
                        src="minus.png"
                        alt="minus"
                        className="minus-btn"
                        id={index}
                        name={row._id}
                        onClick={deleteRow}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="factor-total__cost">
                <span>جمع کل :</span>
                <span>{newTotal.toFixed(2)}</span>
              </div>
              <div className="factor-footer">
                <span>توضیحات</span>
                <input
                  type="text"
                  id="factorDescription"
                  name="factorDescription"
                  onChange={(e) => setFactorDescription(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
