import { Link, useNavigate } from "react-router-dom";
import "../../Styles/StaticComponents/Global.css";
import "../../Styles/StaticComponents/Header.css";
import { Alert } from "../StaticComponents/Alert";
import { BACKUP } from "../../GraphQL/Mutation";
import screenfull from "screenfull";
import { useApolloClient } from "@apollo/client";
import axios from 'axios';
import {
  PoweroffOutlined, ExpandOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { useState } from "react";
export function Header(props) {
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  const [newBackup,setNewBackUp] = useState()
  const address = props.address;
  const navigate = useNavigate();
  const client = useApolloClient();
  function logOut() {
    window.localStorage.removeItem("activeUser");
    window.localStorage.removeItem("profile");
    navigate("/login");
  }
  const toggleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };
  async function handleBackup(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: BACKUP,
        variables: {
          dbName:"oxygen"
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setAlert({
          action: "success",
          alertStatus: "backup",
        });
        axios.get(`https://oxygen-backend.techlands.online/download?path=${res.data.getBackup.message}`)
      .then(response => {
        setNewBackUp(response.data)
        window.open(`https://oxygen-backend.techlands.online/download?path=${res.data.getBackup.message}`, '_blank')
      })
      .catch(error => console.error('Error fetching data:', error));
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      setAlert({
        action: "",
        alertStatus: "",
      });
      console.log(error);
    }
  }

  return (
    <div className="header-container">
    {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
      <div>
        <PoweroffOutlined onClick={logOut} className="logout" title="خروج" />
      </div>
      <span className="header-heading">
        سیستم مدیریتی دارویی اکسیجن <span className="location">{address}</span>
      </span>
      <div onClick={toggleFullscreen} className="fullScreen" id="fullScreen">
        <ExpandOutlined title="فول اسکرین" />
      </div>
      <div onClick={handleBackup} className="backup">
        <CloudDownloadOutlined title="بکاپ" />
      </div>
      <Link to={"/home"}>
        <img src="pharmacy.png" alt="لوگو" className="headerLogo" />
      </Link>
    </div>
  );
}
