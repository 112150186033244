import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { EMPLOYEES } from "../../GraphQL/Query";
import {
  ADDEMPLOYEE,
  DELETEEMPLOYEE,
  UPDATEEMPLOYEE,
} from "../../GraphQL/Mutation";
import { Alert } from "../StaticComponents/Alert";
import {
  Button,
  Input,
  Table,
  Space,
  Tag,
  InputNumber,
  Modal,
  Popconfirm,
} from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
export function Eployee() {
  const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY-MM-DD" })
  const today = date.format()
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [filteredUser, setFilteredUser] = useState();
  const [employees, setEmployees] = useState([]);
  const [searching, setSearching] = useState("");
  const [newEmployee, setNewEmployee] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  if (location.pathname == "/employee") {
    location.pathname = "کارمندان";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadEmployee();
  }, [referesh]);

  const loadEmployee = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: EMPLOYEES,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setEmployees([...res.data.getEmployees]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  function goToPrintEmployee() {
    navigate("/printEmployee");
  }
async function addNewEmployee(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDEMPLOYEE,
        variables: {
          fullName: e.target.name.value,
          phoneNumber: e.target.phone.value,
          job: e.target.job.value,
          contractDate: e.target.inputDate.value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    
          function(a) { return a.charCodeAt(0) & 0xf }     
      ),
          workTime: e.target.workTime.value,
          salary: Number(e.target.salary.value),
        },
      });
      if (res) {
        setNewEmployee(!newEmployee);
        setShowDark(!showDark);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function handleDeleteEmployee(employeeId) {
    try {
      const res = await client.mutate({
        mutation: DELETEEMPLOYEE,
        variables: {
          id: employeeId,
        },
      });
      if (res) {
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "delete",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  function handleEditUser(userId) {
    setShowDark(!showDark);
    const filteredRows = employees.filter((item) => item._id == userId);
    setFilteredUser(filteredRows[0]);
    setEditUser(true);
  }
  async function handleUpdateEmployee(e) {
    e.preventDefault();
    setShowDark(!showDark);
    const id = filteredUser._id;
    try {
      const res = await client.mutate({
        mutation: UPDATEEMPLOYEE,
        variables: {
          employeeId: id,
          fullName: e.target.name.value,
          phoneNumber: e.target.phone.value,
          job: e.target.job.value,
          contractDate: e.target.inputDate.value,
          workTime: e.target.workTime.value,
          salary: Number(e.target.salary.value),
        },
      });
      if (res) {
        setEditUser(!editUser);
        setShowDark(!showDark);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "edit",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center"
    },
    {
      title: "نام",
      dataIndex: "fullName",
      filteredValue: [searching],
      onFilter: (value, record) => {
        return String(record.fullName)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
      },
      render: (fullName, employee) => employee.fullName,
      key: "fullName",
      align: "center"
    },
    {
      title: "وظیفه",
      dataIndex: "job",
      key: "job",
      render: (job, employee) => employee.job,
      align: "center"
    },
    {
      title: "شماره تماس",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNumber, employee) => employee.phoneNumber,
      align: "center"
    },
    {
      title: "قرارداد",
      dataIndex: "contractDate",
      key: "contractDate",
      render: (contractDate, employee) => employee.contractDate,
      align: "center"
    },
    {
      title: "زمان کاری",
      dataIndex: "workTime",
      key: "workTime",
      render: (workTime, employee) => employee.workTime,
      align: "center"
    },
    {
      title: "معاش",
      dataIndex: "salary",
      key: "salary",
      render: (salary, employee) => (
        <Tag bordered={false}
          style={{
            fontSize: 15,
            fontFamily: "B-NAZANIN",
          }}
        >{employee.salary}</Tag>
      ),
      align: "center"
    },
    {
      title: "بلانس",
      dataIndex: "balance",
      key: "balance",
      render: (balance, employee) => (
        <Tag
          bordered={false}
          color={
            employee.balance < 0
              ? "success"
              : employee.balance > 0
                ? "error"
                : ""
          }
        >
          {
            <Space>
              {Math.abs(employee.balance)}
              {""}
              {employee.balance > 0 ? "بستانکار" : employee.balance === 0 ? "تسویه" : "بدهکار"}
            </Space>
          }
        </Tag>
      ),
      align: "center"
    },
    {
      title: userRole === "Admin" ? "عملیات" : null,
      dataIndex: "action",
      key: "action",
      render: (record, employee) => {
        return (
          userRole === "Admin" ?
            <Space className="action-space">
              <EllipsisOutlined
                onClick={() =>
                  navigate("/selectedEmployee", {
                    state: { employeeId: employee._id },
                  })
                }
              />
              <EditOutlined onClick={() => handleEditUser(employee._id)} />
              <Popconfirm
                title="آیا مطمین هستید میخواهید حذف نمایید؟"
                onConfirm={() => handleDeleteEmployee(employee._id)}
                okText="بله"
                cancelText="انصراف"
                placement="topLeft"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Space>
            : null
        );
      },
      align: "center"
    },
  ];
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="file-container">
          <div className="file-header">
            <Input.Search
              placeholder="جستجوی کارمند"
              allowClear
              size="large"
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            {
              userRole === "Admin" &&
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => {
                  setNewEmployee(!newEmployee);
                  setShowDark(!showDark);
                }}
                className="btn"
              >
                ثبت کارمند
              </Button>
            }
            <Button
              type="primary"
              size="large"
              icon={<PrinterOutlined />}
              onClick={goToPrintEmployee}
              className="btn"
            >
              چاپ
            </Button>
          </div>
          <div className="file-content">
            {newEmployee && (
              <Modal
                title="ثبت کارمند جدید"
                open={newEmployee}
                onCancel={() => {
                  setNewEmployee(!newEmployee);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewEmployee}>
                  <Space direction="vertical">
                    <Input
                      id="name"
                      name="name"
                      size="large"
                      placeholder="نام کارمند"
                    />
                    <Input
                      id="phone"
                      name="phone"
                      size="large"
                      placeholder="موبایل کارمند"
                    />
                    <Input
                      id="job"
                      name="job"
                      size="large"
                      placeholder="وظیفه کارمند"
                    />
                    <Space className="inputDate-container">
                      <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={MONTHS}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={today}
                        id="inputDate"
                        name="inputDate"
                      />
                    </Space>
                    <Input
                      placeholder="تایم کاری کارمند"
                      id="workTime"
                      name="workTime"
                      size="large"
                    />
                    <InputNumber
                      min={0}
                      placeholder="معاش کارمند"
                      id="salary"
                      name="salary"
                      size="large"
                      style={{ width: "100%" }}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت کارمند
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            {editUser && (
              <Modal
                title="ویرایش کارمند"
                open={editUser}
                onCancel={() => {
                  setEditUser(!editUser);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleUpdateEmployee}>
                  <Space direction="vertical">
                    <Input
                      id="name"
                      name="name"
                      size="large"
                      placeholder="نام کارمند"
                      defaultValue={filteredUser.fullName}
                    />
                    <Input
                      id="phone"
                      name="phone"
                      size="large"
                      placeholder="موبایل کارمند"
                      defaultValue={filteredUser.phoneNumber}
                    />
                    <Input
                      id="job"
                      name="job"
                      size="large"
                      placeholder="وظیفه کارمند"
                      defaultValue={filteredUser.job}
                    />
                    <Space className="inputDate-container">
                      <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={MONTHS}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={filteredUser.contractDate}
                        id="inputDate"
                        name="inputDate"
                      />
                    </Space>
                    <Input
                      placeholder="تایم کاری کارمند"
                      id="workTime"
                      name="workTime"
                      size="large"
                      defaultValue={filteredUser.workTime}
                    />
                    <InputNumber
                      min={0}
                      placeholder="معاش کارمند"
                      id="salary"
                      name="salary"
                      size="large"
                      style={{ width: "100%" }}
                      defaultValue={filteredUser.salary}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ویرایش کارمند
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={employees}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger: false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
