import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { CHECKS, CUSTOMERS, LASTCHEK } from "../../GraphQL/Query";
import { ADDCHECKIN, DELETECHECK, UPDATECHECK } from "../../GraphQL/Mutation";
import { Alert } from "../StaticComponents/Alert";
import {
  Button,
  Dropdown,
  Menu,
  Input,
  Table,
  Space,
  Tag,
  Select,
  Modal,
  InputNumber,
  Popconfirm,
} from "antd";
import {
  PlusOutlined,
  AlignRightOutlined,
  CaretDownOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  SelectOutlined
} from "@ant-design/icons";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
const { Option } = Select;
export function Checks() {
  const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY-MM-DD" })
  const today = date.format()
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [referesh, setReferesh] = useState();
  const [filteredCheck, setFilteredCheck] = useState();
  const [checks, setChecks] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [searching, setSearching] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [checkInNumber, setCheckInNumber] = useState(1);
  const [checkOutNumber, setCheckOutNumber] = useState(1);
  const [showDark, setShowDark] = useState(false);
  const [newRecivedCheck, setNewRecivedCheck] = useState(false);
  const [newPaymentCheck, setNewPaymentCheck] = useState(false);
  const [editRecivedCheck, setEditRecivedCheck] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  if (location.pathname == "/checks") {
    location.pathname = "اسناد";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadCustomers();
    loadChecks();
    handleLastCheck();
  }, [referesh, checkInNumber, checkOutNumber]);
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center"
    },
    {
      title: "نام",
      dataIndex: "fullName",
      filteredValue: [searching],
      onFilter: (value, check) => {
        return String(check.customer.fullName)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
          ||
          String(check.checkType).startsWith(value)
          ;
      },
      render: (fullName, check) => check.customer.fullName,
      key: "fullName",
      align: "center"
    },
    {
      title: "شماره سند",
      dataIndex: ["checkInNumber", "checkOutNumber"],
      key: "checkNumber",
      render: (checkNumber, check) => (
        <Tag bordered={false}>
          {check.checkInNumber || check.checkOutNumber}
        </Tag>
      ),
      align: "center"
    },
    {
      title: "نوعیت",
      dataIndex: "checkType",
      key: "checkType",
      render: (checkType, check) => (
        <Tag
          bordered={false}
          color={check.checkType == "Check_In" ? "success" : "error"}
        >
          {check.checkType == "Check_In" ? "دریافتی" : "پرداختی"}
        </Tag>
      ),
      align: "center"
    },
    {
      title: "مبلغ",
      dataIndex: "amount",
      key: "amount",
      render: (amount, check) => <Tag bordered={false}
        style={{
          fontSize: 15,
          fontFamily: "B-NAZANIN"
        }}
      >{check.amount}</Tag>,
      align: "center"
    },
    {
      title: "تاریخ",
      dataIndex: "date",
      key: "date",
      render: (date, check) => check.date,
      align: "center"
    },
    {
      title: userRole === "Admin" ? "عملیات" : null,
      dataIndex: "action",
      key: "action",
      render: (record, check) => {
        return (
          userRole === "Admin" ?
            <Space className="action-space">
              <PrinterOutlined
                onClick={() =>
                  navigate("/printCheck", {
                    state: { selectedCheck: check?._id },
                  })
                }
              />
              <EditOutlined onClick={() => handleEditCheck(check?._id)} />
              <Popconfirm
                title="آیا مطمین هستید میخواهید حذف نمایید؟"
                onConfirm={() => handleDeletCheck(check?._id)}
                okText="بله"
                cancelText="انصراف"
                placement="topLeft"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Space>
            : null
        );
      },
      align: "center"
    },
  ];
  function goToPrintChecks() {
    navigate("/printChecks");
  }
  const loadCustomers = async () => {
    try {
      const res = await client.query({
        query: CUSTOMERS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      setCustomers([...res.data.getCustomers]);
    } catch (error) {
      console.log(error);
    }
  };
  const loadChecks = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: CHECKS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setIsLoader(false);
        setChecks([...res.data.getChecks]);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  const handleLastCheck = async () => {
    try {
      const res = await client.query({
        query: LASTCHEK,
        variables: {
          checkType: "Check_In",
        },
        fetchPolicy: "no-cache",
      });
      const res2 = await client.query({
        query: LASTCHEK,
        variables: {
          checkType: "Check_Out",
        },
        fetchPolicy: "no-cache",
      });
      setCheckInNumber(res.data.getLastCheck.checkInNumber + 1);
      setCheckOutNumber(res2.data.getLastCheck.checkOutNumber + 1);
    } catch (error) {
      console.log(error);
    }
  };
  async function handleRecivedCheck(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDCHECKIN,
        variables: {
          checkType: "Check_In",
          date: e.target.inputDate.value,
          amount: Number(e.target.checkAmount.value),
          description: e.target.checkDescription.value,
          customer: selectedCustomerId,
        },
      });
      if (res) {
        setNewRecivedCheck(!newRecivedCheck);
        setShowDark(!showDark);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
        setSelectedCustomer("");
        setSelectedCustomerId("");
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function handlePaymentCheck(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDCHECKIN,
        variables: {
          checkType: "Check_Out",
          date: e.target.inputDate.value,
          amount: Number(e.target.checkAmount.value),
          description: e.target.checkDescription.value,
          customer: selectedCustomerId,
        },
      });
      if (res) {
        setNewPaymentCheck(!newPaymentCheck);
        setShowDark(!showDark);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
        setSelectedCustomer("");
        setSelectedCustomerId("");
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function handleDeletCheck(checkId) {
    try {
      const res = await client.mutate({
        mutation: DELETECHECK,
        variables: {
          id: checkId,
        },
      });
      if (res) {
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "delete",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  function handleEditCheck(checkId) {
    setShowDark(!showDark);
    const filteredRows = checks.filter((item) => item._id == checkId);
    setFilteredCheck(filteredRows[0]);
    setSelectedCustomerId(filteredRows[0].customer._id);
    setEditRecivedCheck(!editRecivedCheck);
  }
  async function handleUpdateCheck(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: UPDATECHECK,
        variables: {
          checkId: filteredCheck._id,
          checkType: filteredCheck.checkType,
          date: e.target.inputDate.value,
          amount: Number(e.target.checkAmount.value),
          description: e.target.checkDescription.value,
          customer: selectedCustomerId,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setEditRecivedCheck(!editRecivedCheck);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "edit",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  const printChecks = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<PrinterOutlined />}
          paddingBlock="0"
          onClick={() => {
            goToPrintChecks();
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          تمام اسناد
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<PrinterOutlined />}
          onClick={() => {
            navigate("/printCheck", {
              state: { checkType: "Check_In" },
            });
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          دریافتی
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          block="true"
          icon={<PrinterOutlined />}
          onClick={() => {
            navigate("/printCheck", {
              state: { checkType: "Check_Out" },
            });
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          پرداختی
        </Button>
      ),
    },
  ];
  const saveChecks = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<PlusOutlined />}
          paddingBlock="0"
          onClick={() => {
            setShowDark(!showDark);
            setNewPaymentCheck(true);
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          پرداختی
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<PlusOutlined />}
          onClick={() => {
            setShowDark(!showDark);
            setNewRecivedCheck(true);
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          دریافتی
        </Button>
      ),
    },
  ];
  const filterChecks = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          همه
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("Check_Out");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          پرداختی
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          onClick={() => {
            setSearching("Check_In");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          دریافتی
        </Button>
      ),
    },
  ];
  const menu1 = (
    <Menu>
      {printChecks.map((printCheck) => (
        <Menu.Item key={printCheck.key}>{printCheck.label}</Menu.Item>
      ))}
    </Menu>
  );
  const menu2 = (
    <Menu>
      {saveChecks.map((saveCheck) => (
        <Menu.Item key={saveCheck.key}>{saveCheck.label}</Menu.Item>
      ))}
    </Menu>
  );
  const menu3 = (
    <Menu>
      {filterChecks.map((filterCheck) => (
        <Menu.Item key={filterCheck.key}>{filterCheck.label}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="file-container">
          <div className="file-header">
            <Input.Search
              placeholder="جستجوی مشتری"
              allowClear
              size="large"
              onSearch={(value) => {
                setSearching(value);
              }}
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            <Dropdown
              overlay={menu3}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className="btn"
            >
              <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                انتخاب سند
              </Button>
            </Dropdown>
            {
              userRole === "Admin" &&
              <Dropdown
                overlay={menu2}
                placement="bottom"
                arrow={{ pointAtCenter: true }}
                className="btn"
              >
                <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                  ثبت سند
                </Button>
              </Dropdown>
            }
            <Dropdown
              overlay={menu1}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className="btn"
            >
              <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                چاپ اسناد
              </Button>
            </Dropdown>
          </div>
          <div className="file-content">
            {editRecivedCheck && (
              <Modal
                title="ثبت سند"
                open={editRecivedCheck}
                onCancel={() => {
                  setEditRecivedCheck(!editRecivedCheck);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleUpdateCheck}>
                  <Space direction="vertical">
                    <Input
                      id="checkNumber"
                      name="checkNumber"
                      size="large"
                      value={
                        filteredCheck?.checkOutNumber ||
                        filteredCheck?.checkInNumber
                      }
                      type="disable"
                    />
                    <Space className="inputDate-container">
                      <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={MONTHS}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={filteredCheck.date}
                        id="inputDate"
                        name="inputDate"
                      />
                    </Space>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="جستجوی مشتری"
                      style={{ width: "100%", direction: "rtl" }}
                      name="stack"
                      onChange={(value) => {
                        setSelectedCustomerId(value);
                      }}
                      size="large"
                      defaultValue={filteredCheck.customer.fullName +
                        " - " +
                        filteredCheck.customer.company +
                        " - " +
                        filteredCheck.customer.city}
                    >
                      {customers.map((customer) => (
                        <Option
                          key={customer._id}
                          value={customer._id}
                          style={{ direction: "rtl" }}
                          onClick={() =>
                            setSelectedCustomer(
                              customer.fullName +
                              " - " +
                              customer.company +
                              " - " +
                              customer.city
                            )
                          }
                        >
                          {customer.fullName +
                            " - " +
                            customer.company +
                            " - " +
                            customer.city}
                        </Option>
                      ))}
                    </Select>
                    <InputNumber
                      placeholder="مبلغ سند"
                      id="checkAmount"
                      name="checkAmount"
                      size="large"
                      min={1}
                      style={{
                        width: "100%",
                      }}
                      defaultValue={filteredCheck?.amount}
                    />
                    <Input
                      placeholder="توضیحات سند"
                      id="checkDescription"
                      name="checkDescription"
                      size="large"
                      defaultValue={filteredCheck?.description}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ویرایش سند
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            {newRecivedCheck && (
              <Modal
                title="ثبت سند دریافتی"
                open={newRecivedCheck}
                onCancel={() => {
                  setNewRecivedCheck(!newRecivedCheck);
                  setSelectedCustomer("");
                  setSelectedCustomerId("");
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleRecivedCheck}>
                  <Space direction="vertical">
                    <Input
                      id="checkNumber"
                      name="checkNumber"
                      size="large"
                      value={checkInNumber}
                      type="disable"
                    />
                    <Space className="inputDate-container">
                      <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={MONTHS}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={today}
                        id="inputDate"
                        name="inputDate"
                      />
                    </Space>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="جستجوی مشتری"
                      style={{ width: "100%", direction: "rtl" }}
                      name="stack"
                      onChange={(value) => {
                        setSelectedCustomerId(value);
                      }}
                      size="large"
                    >
                      {customers.map((customer) => (
                        <Option
                          key={customer._id}
                          value={customer._id}
                          style={{ direction: "rtl" }}
                        >
                          {customer.fullName +
                            " - " +
                            customer.company +
                            " - " +
                            customer.city}
                        </Option>
                      ))}
                    </Select>
                    <InputNumber
                      placeholder="مبلغ سند"
                      id="checkAmount"
                      name="checkAmount"
                      size="large"
                      min={1}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Input
                      placeholder="توضیحات سند"
                      id="checkDescription"
                      name="checkDescription"
                      size="large"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت سند
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            {newPaymentCheck && (
              <Modal
                title="ثبت سند پرداختی"
                open={newPaymentCheck}
                onCancel={() => {
                  setNewPaymentCheck(!newPaymentCheck);
                  setSelectedCustomer("");
                  setSelectedCustomerId("");
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handlePaymentCheck}>
                  <Space
                    direction="vertical"
                  >
                    <Input
                      id="checkNumber"
                      name="checkNumber"
                      size="large"
                      value={checkOutNumber}
                      type="disable"
                    />
                    <Space className="inputDate-container">
                      <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={MONTHS}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={today}
                        id="inputDate"
                        name="inputDate"
                      />
                    </Space>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="جستجوی مشتری"
                      style={{ width: "100%", direction: "rtl" }}
                      name="stack"
                      onChange={(value) => {
                        setSelectedCustomerId(value);
                      }}
                      size="large"
                    >
                      {customers.map((customer) => (
                        <Option
                          key={customer._id}
                          value={customer._id}
                          style={{ direction: "rtl" }}
                        >
                          {customer.fullName +
                            " - " +
                            customer.company +
                            " - " +
                            customer.city}
                        </Option>
                      ))}
                    </Select>
                    <InputNumber
                      placeholder="مبلغ سند"
                      id="checkAmount"
                      name="checkAmount"
                      size="large"
                      min={1}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Input
                      placeholder="توضیحات سند"
                      id="checkDescription"
                      name="checkDescription"
                      size="large"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت سند
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={checks}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger: false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
